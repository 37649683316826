.lyft-page {
    font-family: 'Fira Sans', sans-serif;
    overflow-x: hidden;
  }
  
  .powerpoint-section {
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  .prototype-section {
    margin-top: 50px;
    text-align: center; /* Centering the prototype */
  }
  
  