/* Set default margin and padding for all elements to zero */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body, html {
    height: 100%;
    font-family: 'Fira Sans', sans-serif;
  }
  
  .porchpair-page {
    height: 100%;
  }
  
  .porchpair-image-container {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
  }
  
  .porchpair-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1; /* Ensure the image stays behind the content */
  }
  
  .porchpair-content {
    margin-top: 0vh; /* Ensure content starts after the image */
    background-color: #1C4717; /* Green background */
    color: white; /* White text */
    padding: 20px;
    min-height: 30vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .content-left {
    width: 50%;
    padding: 40px;
    margin-bottom: 20px;
  }
  
  .content-left h2 {
    font-size: 36px; /* Increase header font size */
    /*font-family: 'Baloo 2', 'Open Sans', sans-serif;*/
  }
  
  .content-left p {
    margin-bottom: 48px; /* Adds space between paragraphs */
    font-size: 16.5px;
    line-height: 1.7;
    text-align: left;
    font-family: 'Baloo 2', 'Open Sans', sans-serif;
  }
  
  .content-right {
    width: 50%;
    position: relative;
  }
  
  .overlay-image {
    position: absolute;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .image1 {
    width: 269px;
    height: 381px;
    top: 0;
    left: 125px;
    outline: 2px solid white;
  }
  
  .image2 {
    width: 250px;
    height: 375px;
    top: 350px;
    left: 375px;
    outline: 2px solid white;
  }
  
  .image3 {
    width: 256px;
    height: 375px;
    top: 700px;
    left: 125px;
    outline: 2px solid white;
  }
  