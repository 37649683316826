body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Fira Sans', sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.landing-image {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  display: block;
}

.spacing {
  padding: 40px;
  background-color: #000;
}

.grid-section {
  padding: 20px;
  padding-top: 100px;
  background-color: #000; /* Black background */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 400px);
  grid-template-rows: repeat(2, 400px);
  gap: 40px;
  justify-content: center;
}

.grid-item {
  width: 400px;
  height: 400px;
  border-radius: 15px; /* Rounded corners */
  overflow: hidden; /* Ensures the rounded corners are effective */
  transition: outline 0.3s; /* Smooth transition for the outline */
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-item:hover {
  outline: 3px solid white; /* White outline on hover */
}

.footer {
  background-color: #0b0b0b;
  color: #d8d8d8;
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.footer-section {
  margin: 10px;
  flex: 1;
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 18px;
}

.footer-section p, .footer-section a {
  color: #d8d8d8;
  text-decoration: none;
  font-size: 14px;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  border-top: 1px solid #444;
  margin-top: 20px;
}
