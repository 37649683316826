.header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #0b0b0b;
    border-bottom: 1px solid #ddd;
    font-family: Raleway, sans-serif;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .header-image {
    width: 100px;
    height: 100px;
    margin-left: 30px;
    border-radius: 50px;
  }
  
  .header-title {
    font-size: 24px;
    color: rgb(216, 216, 216);
    margin-right: auto;
    margin-left: 30px;
    display: flex;
    align-items: center;
  }
  
  .header-nav {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  
  
  .header-link {
    text-decoration: none;
    color: rgb(216, 216, 216);
    font-size: 18px;
  }
  
  .header-link:hover {
    text-decoration: underline;
  }
  
  .header-right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .header-linkedin {
    margin-right: 40px;
  }
  
  .linkedin-icon {
    width: 35px;
    height: 35px;
  }
  
  .built-with {
    color: gray;
    font-size: 14px;
  }
  