@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

.seoul-juice-page {
    padding: 20px;
    font-family: 'Fira Sans', sans-serif;
  }
  
  .intro-section {
    margin-bottom: 40px;
    background-color: #ba2504;
    color: white;
    border-radius: 50px;
  }
  
  .intro-section h1 {
    font-size: 36px;
    background-color: #ba2504;
    color: white;
    text-align: left;
    padding-left: 25px;
    padding-top: 25px;
    padding-bottom: 20px;
    
  }
  
  .intro-section p {
    font-size: 18px;
    line-height: 1.6;
    background-color: #ba2504;
    color: white;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;
    font-family: 'Baloo 2', 'Open Sans', sans-serif;
  }
  
  .content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .sj-logo {
    width: 250px;
    height: 65px;
    margin-bottom: 20px;
  }
  
  .headings {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    font-family: 'Merriweather', serif;
    
  }
  
  .headings h2 {
    font-size: 24px;
    cursor: pointer;
    color: #0b0b0b;
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .headings h2:hover {
    text-decoration: underline;
  }
  
  .roll {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .roll-image {
    height: 500px; /* Example height, adjust as needed */
    width: 1200px;
    border-radius: 0px;
  }
  